<div class="dialog">
    <!-- <div (click)="onNoClick()" class="dialog-close">
        <mat-icon>close</mat-icon>
    </div> -->
    <img class="dialog-image" src="../assets/images/house.svg" />
    <div class="dialog-title">
        REVISA LOS HORARIOS DE NUESTROS ENTRENAMIENTOS ONLINE
    </div>
    <div class="dialog-text">
        Las actividades presenciales quedan temporalmente suspendidas en virtud de la Pandemia - Corona Virus <br>(Covid-19)
    </div>
    <div class="dialog-text">
        Seguinos desde instagram en <b>@bikersclubpy</b>
    </div>
    <div class="divider"></div>
    <div (click)="onNoClick()" class="dialog-close">Seguir Navegando</div>
</div>