import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent, DialogOverviewDialog } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_INITIALIZERS } from './initializers';

import { APP_STORE_MODULES } from './stores-modules';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';

import { HttpModule, CookieXSRFStrategy } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';
import { Ae2CoreModule } from '@angularecommerce/core/modules/core';
import {
  Ae2MyAccountGlobalConfig,
  MenuInterface,
  AE2_MY_ACCOUNT_CONFIG_TOKEN,
} from '@angularecommerce/core/components/my-account';
// import { Ae2SwService } from '@angularecommerce/core/services/sw/sw.service';

/**
* Comentado pois o uso de service worker provoca erros na dashboard,
* por estar no mesmo domínio
*/
// import { ServiceWorkerModule } from '@angular/service-worker';

export class MyAccountCustomConfig extends Ae2MyAccountGlobalConfig {
  // Menus
  menu: MenuInterface[] = [];

  showCpf: boolean = false;

  showCep: boolean = false;

  showAddressType: boolean = false;

  makeFieldsNotRequired: boolean = false;

  showRecipient: boolean = false;

  constructor() {
    super();
    this.menu.push({ name: 'Mis datos', link: 'meus-dados', icon: 'account_circle' });
    this.menu.push({ name: 'Cambiar mi contraseña', link: 'alterar-senha', icon: 'lock' });
    // this.menu.push({ name: 'Mis direcciones', link: 'meus-enderecos', icon: 'room' });
    // this.menu.push({ name: 'Cartões de Crédito', link: 'metodos-pagamento', icon: 'credit_card' });
    this.menu.push({ name: 'Mis órdenes', link: 'meus-pedidos', icon: 'bookmark_border' });
    this.menu.push({ name: 'Mis suscripciones', link: 'minhas-assinaturas', icon: 'bookmark' });
    this.menu.push({ name: 'Mis eventos', link: 'meus-eventos', icon: 'event' });
    this.menu.push({ name: 'Horario Fijo', link: 'meus-eventos-automaticos', icon: 'event' });
    this.menu.push({ name: 'Mis créditos', link: 'meus-creditos', icon: 'account_balance' });
    this.menu.push({ name: 'Créditos compartidos', link: 'compartilhar-creditos', icon: 'share' });
    this.menu.push({ name: 'Datos de rendimiento', link: 'dados-de-performance', icon: 'timeline' });

  }
}

@NgModule({
  declarations: [
    AppComponent,
    DialogOverviewDialog
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,

    /**
    * Comentado pois o uso de service worker provoca erros na dashboard,
    * por estar no mesmo domínio
    */
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),

    AppRoutingModule,
    Ae2CoreModule,
    APP_STORE_MODULES,
    APP_INITIALIZERS,
    CommonModule,
    HeaderModule,
    FooterModule,
    RouterModule,
  ],
  providers: [
    // APP_INITIALIZERS,
    { provide: AE2_MY_ACCOUNT_CONFIG_TOKEN, useClass: MyAccountCustomConfig }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogOverviewDialog]
})
export class AppModule {

  constructor() {

    /**
     * Comentado pois o uso de service worker provoca erros na dashboard,
     * por estar no mesmo domínio
     */
   // this.ae2SwService.checkForUpdate();
  }

}
