import { Ae2ActivitiesService } from '@angularecommerce/core/services/activities';
import { Ae2SignUpTopModule } from '@angularecommerce/core/components/sign-up-top';
import { NgModule } from '@angular/core';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    Ae2HeaderWidgetModule,
    Ae2SignUpTopModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [Ae2ActivitiesService]
})
export class HeaderModule { }
