<section class="contact">
  <aside class="contact_header">
    <h1 class="contact_header_title contact_title">CONTÁCTENOS</h1>
    <!--<p class="text_contact">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
            in reprehenderit</p>-->
  </aside>
  <aside class="contact_content">
    <ae2-contact-form code="contato" subject="Contado" [fields]="{
                name: { required: true },
                email: { required: true, validEmail: true },
                phone: { required: true },
                subject: { required: true},
                message: { required: true }
            }">

      <ng-template #contactFormTemplate let-form="form" let-methods="methods">
        <form [formGroup]="form" (submit)="methods.submit($event)" fxLayout fxLayout.xs="column" fxLayoutGap="20px">

          <div class="contact_content_item" fxFlex="calc(50% - 20px)" fxFlex.xs="auto">
            <div class="contact_content_item">
              <input class="input full" placeholder="Nombre" formControlName="name">
              <ae2-control-messages [control]="form.get('name')"></ae2-control-messages>
            </div>

            <div class="contact_content_item">
              <input class="input full" placeholder="Email" formControlName="email">
              <ae2-control-messages [control]="form.get('email')"></ae2-control-messages>
            </div>

            <div class="contact_content_item_full">
              <input class="input full" matInput placeholder="Celular" ae2Mask="9999 999999" [specialCharacters]="true"
                formControlName="phone">
              <ae2-control-messages [control]="form.get('phone')"></ae2-control-messages>
            </div>
            <div class="selectWrapper">
              <select class="select full" placeholder="Asunto" formControlName="subject">
                <option selected [value]="null">-- Asunto --</option>
                <option value="Horarios">Horarios</option>
                <option value="Compras">Compras</option>
                <option value="Soporte">Soporte</option>
                <option value="Quejas">Quejas</option>
                <option value="Otros">Otros</option>
              </select>
            </div>
            <ae2-control-messages [control]="form.get('subject')"></ae2-control-messages>
          </div>

          <div class="contact_content_item" fxFlex="calc(50% - 20px)" fxFlex.xs="auto">
            <textarea class="full textarea" rows="5" placeholder="Mensaje" formControlName="message"></textarea>
            <ae2-control-messages [control]="form.get('message')"></ae2-control-messages>
            <div class="contact_content_item" fxLayout fxLayoutAlign="end center">
              <!--[disabled]="form.invalid"-->
              <button class="contact_content_item_button" type="submit" mat-raised-button>Enviar</button>
            </div>
          </div>


        </form>
      </ng-template>
    </ae2-contact-form>

  </aside>
</section>
