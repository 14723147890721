import {
  Ae2SignUpStepAddress,
  Ae2SignUpStepFitness,
  Ae2SignUpStepProfile,
  Ae2SignUpStepSignup
} from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import {
  Ae2SignUpField,
  Ae2SignUpFieldGroup,
  Ae2SignupFieldTypes,
  Ae2SignUpFieldValidator,
  Ae2SignUpStepperConfig
} from '@angularecommerce/core/components/sign-up-steps';
import {
  Ae2HeaderLinkToPage,
  Ae2HeaderLinkToSession,
  Ae2HeaderMenu,
  Ae2HeaderSubmenu,
  Ae2HeaderWidgetConfig
} from '@angularecommerce/core/components/header-widget';
import { Component, OnInit } from '@angular/core';


export class Ae2SignUpStepProfile2 extends Ae2SignUpFieldGroup {

  constructor() {
    super();

    this.fields = [
      new Ae2SignUpField({
        name: 'firstName',
        internalname: 'first_name',
        label: 'Nombre',
        validators: new Ae2SignUpFieldValidator({ required: true }),
        row: 1,
        size: '48%',
        onBlur: context => {
          const firstName = context.stepForm.get('firstName').value;
          const lastName = context.stepForm.get('lastName').value;
          const nickname = `${firstName} ${lastName}`;
          context.stepperConfig.savedData['nickname'] = nickname;
        }
      }),
      new Ae2SignUpField({
        name: 'lastName',
        internalname: 'last_name',
        label: 'Apellido',
        validators: new Ae2SignUpFieldValidator({ required: true }),
        row: 1,
        size: '48%',
        onBlur: context => {
          const firstName = context.stepForm.get('firstName').value;
          const lastName = context.stepForm.get('lastName').value;
          const nickname = `${firstName} ${lastName}`;
          context.stepperConfig.savedData['nickname'] = nickname;
        }
      }),
      new Ae2SignUpField({
        name: 'gender',
        label: 'Género *',
        validators: new Ae2SignUpFieldValidator({
          type: Ae2SignupFieldTypes.SELECT, required: true, options: [
            { value: 'M', label: 'Masculino' },
            { value: 'F', label: 'Femenino' }
          ]
        }),
        row: 2,
        size: '48%'
      }),
      new Ae2SignUpField({
        name: 'dateOfBirth',
        internalname: 'date_of_birth',
        label: 'Fecha de nacimiento',
        validators: new Ae2SignUpFieldValidator({ required: true, mask: '99/99/9999', minlength: 10 }),
        row: 2,
        size: '48%'
      }),
      // new Ae2SignUpField({
      //     name: 'imNotBrazilian',
      //     label: 'Soy extranjero',
      //     validators: new Ae2SignUpFieldValidator({
      //         type: Ae2SignupFieldTypes.CHECKBOX,
      //         value: false
      //     }),
      //     row: 3,
      //     size: '48%',
      //     onChange: context => {
      //         const isNotBrazilian = context.stepForm.get('imNotBrazilian').value;
      //         const mobilePhone = this.fields.find(field => field.name === 'mobilePhone');
      //         const passportField = this.fields.find(field => field.name === 'foreignDocument');

      //         if (isNotBrazilian) {
      //             mobilePhone.validators.mask = '+99 9999999999999999';
      //         } else {
      //             mobilePhone.validators.mask = '9999-999999';
      //         }

      //         if (passportField) {
      //             passportField.isVisible = isNotBrazilian;
      //         }
      //         context.changeFieldsVisibilityInCurrentForm(this.fields);
      //     }
      // }),
      new Ae2SignUpField({
        name: 'mobilePhone',
        internalname: 'mobile_phone',
        label: 'Celular',
        validators: new Ae2SignUpFieldValidator({ required: true, mask: '9999-999999' }),
        row: 3,
        size: '48%'
      }),
      new Ae2SignUpField({
        name: 'foreignDocument',
        label: 'Pasaporte',
        isVisible: false,
        validators: new Ae2SignUpFieldValidator({ required: true }),
        row: 4,
        size: '48%'
      })
    ];
  }
}

export class Ae2SignUpStepAddress2 extends Ae2SignUpFieldGroup {

  constructor() {
    super();

    this.fields = [
      new Ae2SignUpField({
        name: 'address1',
        label: 'Domicilio',
        validators: new Ae2SignUpFieldValidator({ required: false }),
        row: 1,
        size: '48%'
      }),
      new Ae2SignUpField({
        name: 'address2',
        label: 'Número',
        validators: new Ae2SignUpFieldValidator({ required: false }),
        row: 1,
        size: '48%'
      }),
      new Ae2SignUpField({
        name: 'address3',
        label: 'Complemento',
        validators: new Ae2SignUpFieldValidator({ required: false }),
        row: 2,
        size: '48%'
      }),
      new Ae2SignUpField({
        name: 'city',
        label: 'Ciudad',
        validators: new Ae2SignUpFieldValidator({ required: true }),
        row: 2,
        size: '48%'
      }),
      // new Ae2SignUpField({
      //   name: 'country',
      //   label: 'País',
      //   validators: new Ae2SignUpFieldValidator({ required: false }),
      //   row: 3,
      //   size: '100%'
      // })

    ];
  }
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
  signupConfig: Ae2SignUpStepperConfig;

  constructor() { }

  ngOnInit(): void {
    this.createSignupConfig();
    this.setHeaderConfig();
  }


  // setFirstActivity(): void {
  //     const paginator = this.ae2ActivitiesService.getAll();
  //     paginator.getData$()
  //         .subscribe(res => {
  //             if (res.results[0]) {
  //                 this.firstActivity = res.results[0];
  //             }
  //             this.setHeaderConfig();
  //         });
  // }


  setHeaderConfig(): void {
    this.headerConfig = new Ae2HeaderWidgetConfig();
    this.headerConfig.menuItems = new Ae2HeaderMenu();
    this.headerConfig.menuItems.items = [
      new Ae2HeaderSubmenu('BIKERS CLUB', [
        new Ae2HeaderLinkToPage('QUIÉNES SOMOS', '/quienes-somos'),
        new Ae2HeaderLinkToPage('PREMIUM INDOOR CYCLING', '/atividades/'),
        new Ae2HeaderLinkToPage('DÓNDE ESTAMOS', '/donde-estamos'),
        new Ae2HeaderLinkToPage('CONTÁCTENOS', '/contactenos')
      ]),

      new Ae2HeaderLinkToPage('HORARIOS', '/horarios'),
      new Ae2HeaderLinkToPage('PACKS', '/packs')
    ];

    this.headerConfig.transparentOnRoutes = [];
    this.headerConfig.backgroundColor = '#FEEE00';
  }


  createSignupConfig(): void {
    this.signupConfig = new Ae2SignUpStepperConfig([
      new Ae2SignUpStepSignup(),
      new Ae2SignUpStepProfile2(),
      new Ae2SignUpStepAddress2(),
      new Ae2SignUpStepFitness(),
    ]);
    this.signupConfig.hiddenFields = {
      country: 'PY'
   };
  }

}
