var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Ae2MyAccountGlobalConfig, MenuInterface, } from '@angularecommerce/core/components/my-account';
// import { Ae2SwService } from '@angularecommerce/core/services/sw/sw.service';
/**
* Comentado pois o uso de service worker provoca erros na dashboard,
* por estar no mesmo domínio
*/
// import { ServiceWorkerModule } from '@angular/service-worker';
var MyAccountCustomConfig = /** @class */ (function (_super) {
    __extends(MyAccountCustomConfig, _super);
    function MyAccountCustomConfig() {
        var _this = _super.call(this) || this;
        // Menus
        _this.menu = [];
        _this.showCpf = false;
        _this.showCep = false;
        _this.showAddressType = false;
        _this.makeFieldsNotRequired = false;
        _this.showRecipient = false;
        _this.menu.push({ name: 'Mis datos', link: 'meus-dados', icon: 'account_circle' });
        _this.menu.push({ name: 'Cambiar mi contraseña', link: 'alterar-senha', icon: 'lock' });
        // this.menu.push({ name: 'Mis direcciones', link: 'meus-enderecos', icon: 'room' });
        // this.menu.push({ name: 'Cartões de Crédito', link: 'metodos-pagamento', icon: 'credit_card' });
        _this.menu.push({ name: 'Mis órdenes', link: 'meus-pedidos', icon: 'bookmark_border' });
        _this.menu.push({ name: 'Mis suscripciones', link: 'minhas-assinaturas', icon: 'bookmark' });
        _this.menu.push({ name: 'Mis eventos', link: 'meus-eventos', icon: 'event' });
        _this.menu.push({ name: 'Horario Fijo', link: 'meus-eventos-automaticos', icon: 'event' });
        _this.menu.push({ name: 'Mis créditos', link: 'meus-creditos', icon: 'account_balance' });
        _this.menu.push({ name: 'Créditos compartidos', link: 'compartilhar-creditos', icon: 'share' });
        _this.menu.push({ name: 'Datos de rendimiento', link: 'dados-de-performance', icon: 'timeline' });
        return _this;
    }
    return MyAccountCustomConfig;
}(Ae2MyAccountGlobalConfig));
export { MyAccountCustomConfig };
var AppModule = /** @class */ (function () {
    function AppModule() {
        /**
         * Comentado pois o uso de service worker provoca erros na dashboard,
         * por estar no mesmo domínio
         */
        // this.ae2SwService.checkForUpdate();
    }
    return AppModule;
}());
export { AppModule };
