<ae2-footer>

  <ng-template #templateRef let-settings>
    <footer class="ae2-footer">
      <div class="ae2-footer_top">
        <div class="ae2-footer-session first_session" fxLayout fxLayoutAlign="center center">
          <img fxFlex="30%" class="logo_footer" src="/assets/images/logoBikers.png" alt="">
          <p fxFlex="60%">#Bikeoholic #JoinTheClub #CardioParty</p>
        </div>
        <div class="ae2-footer-session">
          <a class="footer_link_item" href="#" routerLink="/faq">
            FAQ
          </a>
          <a class="footer_link_item" href="#" routerLink="/sugerencias">
            Sugerencias
          </a>
          <a class="footer_link_item" href="#" routerLink="/politica-de-privacidade">
            Política de privacidad
          </a>
          <a class="footer_link_item" taget="_blank" href="/media/terms-and-conditions/termos-e-condicoes.pdf">
            Términos y Condiciones
          </a>
        </div>
        <div class="ae2-footer-session">

          <a class="payment_text" href="#">Medios de Pago</a>
          <div fxLayout="row" class="payment_block">
            <div fxFlex="50%">
              <p>Cash</p>
              <p>Cheque</p>
              <p>Tarjeta de débito</p>
            </div>
            <div fxFlex="50%">
              <p>Transferencia</p>
              <p>Débito automático</p>
              <p>Tarjeta de crédito</p>
            </div>
          </div>
        </div>
        <div class="ae2-footer-session">
          <div class="ae2-footer-address">
            <div class="footer-address_row">
              <div class="footer-address_label">Dirección</div>
              <div class="footer-address_value">
                {{ settings.address1 }}, {{ settings.address2 }}, {{ settings.address3 }} - {{ settings.city }}
              </div>
            </div>
            <div class="footer-address_row">
              <div class="footer-address_label">Contacto</div>
              <div class="footer-address_value">
                {{ settings.mobile }}<br>
                {{ settings.phone }}
              </div>
            </div>
          </div>

          <ae2-footer-follow></ae2-footer-follow>
        </div>
      </div>
      <div class="ae2-footer_bottom">
        <span>Copyright © 2018 - Todos los derechos reservados</span>
        <span>
          <a href="https://angulare.app/?utm_source=cws&utm_campaign=bikersclub" target="_blank" class="ae2-footer_bottom_powered">Powered by Angular
            e-Commerce</a>
        </span>
      </div>
    </footer>
  </ng-template>
</ae2-footer>
