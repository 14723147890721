import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { Ae2FooterModule } from '@angularecommerce/core/components/footer';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    Ae2FooterModule,
    CommonModule,
    RouterModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
