import { Component, HostListener, OnInit } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  sidenavMode: string;
  sidenavOpened: boolean = false;
  sidenavAlign: string;
  showHeader: boolean = true;
  showFooter: boolean = true;
  isHeaderFixed: boolean = false;
  isHeaderTransparent: boolean = false;

  constructor(
    private observableMedia: ObservableMedia,
    public dialog: MatDialog
  ) {  }

  ngOnInit(): void {
    this.observerMQ();
    // setTimeout(() => {
    //   this.openDialog()
    // }, 500);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.observerMQ();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewDialog, {
      width: this.observableMedia.isActive('gt-sm') ? '495px' : '300px',
    });
  }

  observerMQ(): void {
    if (this.observableMedia.isActive('gt-sm')) {
      this.sidenavMode = 'side';
      this.sidenavOpened = true;
      this.sidenavAlign = 'start';
    } else {
      this.sidenavOpened = false;
      this.sidenavMode = 'push';
      this.sidenavAlign = 'start';
    }
  }

  setHeaderFixed(headerState: any): void {
    this.isHeaderFixed = headerState.fixed;
    this.isHeaderTransparent = headerState.transparent;
  }
}


@Component({
  selector: 'app-dialog-pop-up',
  templateUrl: 'app-dialog-pop-up.html',
  styleUrls: ['./app.component.scss']
})
export class DialogOverviewDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewDialog>,
    ) {

    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}