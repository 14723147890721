import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { MatButtonModule } from '@angular/material';
import { ListUnitsModule } from '../../shared/components/list-units/list-units.module';

import { ContactModule } from '../../shared/components/contact/contact.module';


@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    MatButtonModule,
    Ae2CarouselContainerModule,
    ContactModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
