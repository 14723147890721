<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
    <ng-template ae2CarouselOverlayContent 
    let-title="title" 
    let-description="description" 
    let-ctaLabel="ctaLabel" 
    let-ctaUrl="ctaUrl">
        <div class="ae2Carousel__overlay-content-default">
            <button class="ae2Carousel__overlay-content-default__button" mat-raised-button [routerLink]="'/horarios'">Ver Horarios</button>
            <button class="ae2Carousel__overlay-content-default__button" mat-raised-button [routerLink]="'/packs'">Ver Packs</button>
        </div>
    </ng-template>
</ae2-carousel-container>

<!--
<app-newsletter [open]="open"></app-newsletter>

<app-list-units id="estudio"></app-list-units>

<app-activities id="atividades"></app-activities>

<app-trainers id="instrutores"></app-trainers>

<app-package id="pacote"></app-package>

<app-calendar-home id="agenda"></app-calendar-home>

<app-contact id="contato"></app-contact>-->