import { Routes } from '@angular/router';
import { Ae2AuthRouteGuardService } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
var ɵ0 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ1 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ2 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ3 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ4 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ5 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ6 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ7 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ8 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ9 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ10 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ11 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ12 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ13 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ14 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ15 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ16 = { someProperty: AE2_ROUTER_CLASSES_DATA };
var routes = [
    {
        path: '',
        component: HomeComponent,
    },
    // {
    //   path: 'login',
    //   loadChildren: 'app/features/login/login.module#LoginModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'categoria/:categoryId',
    //   loadChildren: 'app/features/products/products.module#ProductsModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'categoria/:categoryId/subcategoria/:subcategoryId',
    //   loadChildren: 'app/features/products/products.module#ProductsModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
    //   loadChildren: 'app/features/products/products.module#ProductsModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'online/class',
        loadChildren: 'app/features/online-class/online-class.module#OnlineClassModule'
    },
    {
        path: 'online/thank-you',
        loadChildren: 'app/features/online-class-thanks/online-class-thanks.module#OnlineClassThanksModule'
    },
    {
        path: 'online/already-connected',
        loadChildren: 'app/features/online-class-disconnected/online-class-disconnected.module#OnlineClassDisconnectedModule'
    },
    {
        path: 'minha-conta',
        canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/profile/profile.module#ProfileModule',
        data: ɵ0
    },
    {
        path: 'checkout',
        loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
        data: ɵ1
    },
    {
        path: 'horarios',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
        data: ɵ2
    },
    {
        path: 'contactenos',
        loadChildren: 'app/shared/components/contact/contact.module#ContactModule',
        data: ɵ3
    },
    {
        path: 'sugerencias',
        loadChildren: 'app/shared/components/sugerencias/sugerencias.module#SugerenciasModule',
        data: ɵ4
    },
    {
        path: 'donde-estamos',
        loadChildren: 'app/shared/components/list-units/list-units.module#ListUnitsModule',
        data: ɵ5
    },
    {
        path: 'quienes-somos',
        loadChildren: 'app/shared/components/quienes-somos/quienes-somos.module#QuienesSomosModule',
        data: ɵ6
    },
    {
        path: 'evento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ7
    },
    // {
    //   path: 'atividades',
    //   loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'atividades',
        loadChildren: 'app/features/activity/activity.module#ActivityModule',
        data: ɵ8
    },
    {
        path: 'atividades/tickets/:slug/:id',
        loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
        data: ɵ9
    },
    {
        path: 'atividades/:slugatividade/:slugevento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ10
    },
    // {
    //   path: 'instrutor/:slug/:id',
    //   loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'clientes/resetar-senha/:uidb/:token',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ11
    },
    {
        path: 'clientes/resetar-senha',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ12
    },
    {
        path: 'faq',
        loadChildren: 'app/features/faq/faq.module#FaqModule',
        data: ɵ13
    },
    {
        path: 'termos-e-condicoes',
        loadChildren: 'app/features/termos/termos.module#TermosModule',
        data: ɵ14
    },
    {
        path: 'politica-de-privacidade',
        loadChildren: 'app/features/policy/policy.module#PolicyModule',
        data: ɵ15
    },
    // {
    //   path: 'depoimentos',
    //   loadChildren: 'app/features/testimonials/testimonials.module#TestimonialsModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'lp/:slug',
    //   loadChildren: 'app/features/flat-pages/flat-pages.module#FlatPagesModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'blog',
    //   loadChildren: 'app/features/blog/blog.module#BlogModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'packs',
        loadChildren: 'app/features/packages/packages.module#PackagesModule',
        data: ɵ16
    },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
