/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/header/header.component.ngfactory";
import * as i3 from "./shared/components/header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "./shared/components/footer/footer.component.ngfactory";
import * as i6 from "./shared/components/footer/footer.component";
import * as i7 from "./app.component";
import * as i8 from "@angular/flex-layout";
import * as i9 from "@angular/material/dialog";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-footer", [], [[2, "footer", null]], null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i1.ɵdid(5, 49152, null, 0, i6.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 4, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.ObservableMedia, i9.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
var styles_DialogOverviewDialog = [i0.styles];
var RenderType_DialogOverviewDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogOverviewDialog, data: {} });
export { RenderType_DialogOverviewDialog as RenderType_DialogOverviewDialog };
export function View_DialogOverviewDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "dialog-image"], ["src", "../assets/images/house.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" REVISA LOS HORARIOS DE NUESTROS ENTRENAMIENTOS ONLINE "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "dialog-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Las actividades presenciales quedan temporalmente suspendidas en virtud de la Pandemia - Corona Virus "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(Covid-19) "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "dialog-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Seguinos desde instagram en "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["@bikersclubpy"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "dialog-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Seguir Navegando"]))], null, null); }
export function View_DialogOverviewDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-pop-up", [], null, null, null, View_DialogOverviewDialog_0, RenderType_DialogOverviewDialog)), i1.ɵdid(1, 49152, null, 0, i7.DialogOverviewDialog, [i9.MatDialogRef], null, null)], null, null); }
var DialogOverviewDialogNgFactory = i1.ɵccf("app-dialog-pop-up", i7.DialogOverviewDialog, View_DialogOverviewDialog_Host_0, {}, {}, []);
export { DialogOverviewDialogNgFactory as DialogOverviewDialogNgFactory };
